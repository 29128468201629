@import url('https://fonts.googleapis.com/css2?family=Schoolbell&family=Ubuntu&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

html,

* {
  font-family: 'Ubuntu';

  h1 {
    font-size: 36pt;
  }

  h2 {
    font-size: 24pt;
    margin-bottom: 20px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu';
  // font-family:
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  //   "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  //   "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

